'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const ipfsCore = '0.12.2';
const commit = '6178708aedf5ea86bf537862c08db49f5b8ea20b';
const interfaceIpfsCore = '^0.152.2';

exports.commit = commit;
exports.interfaceIpfsCore = interfaceIpfsCore;
exports.ipfsCore = ipfsCore;
